import { graphql } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import PropTypes from 'prop-types';
import * as React from 'react';

import {
  ContactForm,
  ContactInfo,
  FindOutMore,
  Layout,
  RescueService,
  SEO,
} from '../components';
import { FigCaption } from '../components/figcaption';

function ContactPage({ data }) {
  return (
    <Layout
      heroImage={data.sanityContactPage.heroImage.asset.fluid}
      heroAltText={data.sanityContactPage.heroImage.altText}
    >
      <SEO title="Contact" />
      <ContactSection data={data} />
      <RescueService />
      <ContactForm />
      <FindOutMore variant="GRAY" />
    </Layout>
  );
}

ContactPage.propTypes = {
  data: PropTypes.shape({
    sanityContactPage: PropTypes.shape({
      heroImage: PropTypes.shape({
        asset: PropTypes.shape({
          fluid: PropTypes.object.isRequired,
        }),
        altText: PropTypes.string,
      }),
      secondaryImage: PropTypes.shape({
        asset: PropTypes.shape({
          fluid: PropTypes.object.isRequired,
        }),
        altText: PropTypes.string,
      }),
    }),
  }),
};

function ContactSection({ data }) {
  const image = data.sanityContactPage.secondaryImage;
  return (
    <article className="w-full mx-auto bg-white max-w-7xl">
      <div className="grid grid-cols-2 gap-8">
        <div className="flex flex-col justify-center col-span-2 px-4 py-20 mt-8 sm:px-6 md:mt-0 lg:col-span-1 lg:px-8">
          <h1 className="text-4xl font-bold tracking-wider text-green">
            Contact us
          </h1>
          <div className="mt-5 prose-lg">
            <ContactInfo />
          </div>
        </div>

        <div className="col-span-2 lg:col-span-1">
          <div className="relative h-0 aspect-w-16 aspect-h-9 lg:aspect-w-3 lg:aspect-h-4">
            <div className="absolute inset-0">
              <figure className="absolute inset-0 flex flex-1">
                <GatsbyImage
                  fluid={image.asset.fluid}
                  imgStyle={{
                    objectPosition:
                      image.hotspot?.x && image.hotspot?.y
                        ? `${image.hotspot.x * 100}% ${image.hotspot.y * 100}%`
                        : null,
                  }}
                  className="flex-1"
                />
                {image.altText && <FigCaption caption={image.altText} />}
              </figure>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}

export const query = graphql`
  {
    sanityContactPage {
      heroImage {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
        altText
      }
      secondaryImage {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
        altText
      }
    }
  }
`;

export default ContactPage;
